import React from "react"
import { normalize } from "styled-normalize"
import { Link } from "gatsby"
import styled from "styled-components"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/600.css"

const FourOFour = () => {
  return (
    <Wrapper>
      <Title>I'm sorry. I couldn't find that page.</Title>
      <StyledLink to="/">Click here to return.</StyledLink>
    </Wrapper>
  )
}

const StyledLink = styled((props) => <Link {...props} />)`
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  font-family: montserrat;
  text-decoration: none;
`

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 800;
  font-family: montserrat;
`

export default FourOFour
